import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/bookPageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`"At the software-architecture level, the complexity of a problem is reduced by dividing the system into subsystems."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"The goal of all software-design techniques is to break a complicated problem into simple pieces."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`The steps in designing with objects are`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`Identify the objects and their attributes (methods and data).`}</li>
        <li parentName="ul">{`Determine what can be done to each object.`}</li>
        <li parentName="ul">{`Determine what each object is allowed to do to other objects.`}</li>
        <li parentName="ul">{`Determine the parts of each object that will be visible to other objects - which parts will be public and which will be private.`}</li>
        <li parentName="ul">{`Define each object's public interface.`}</li>
      </ul>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"When software-project surveys report causes of project failure, they rarely identify technical reasons as the primary causes of project failure."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Projects fail most often because of poor requirements, poor planning, or poor management. But when projects do fail for reasons that are primarily technical, the reason is often uncontrolled complexity."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Managing complexity is the most important technical topic in software development. In my view, it's so important that Software's Primary Technical Imperative has to be `}<em parentName="p">{`managing complexity`}</em>{`"`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      